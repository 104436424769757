.wrapper {
	background-color: var(--white);
	padding: 100px 5%;
}

.scaledContainer {
	width: 60%;
	z-index: 0;
}

.scaled {
	text-align: start;
	letter-spacing: -2px;
	line-height: 30px;
}

.cardContainer {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 40px;
	padding: 80px 0 80px 5%;
}

.ctaContainer {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}


@media screen and (max-width: 1280px) {
	.cardContainer {
		grid-template-columns: 1fr 1fr;
		padding-right: 5%;
	}
}

@media screen and (max-width: 768px) {
	.wrapper {
		padding: 80px 20px;
	}

	.scaledContainer {
		width: 100%;
	}

	.scaled {
		text-align: center;
	}

	.cardContainer {
		grid-template-columns: 1fr;
		padding: 60px 0 0;
		gap: 20px;
	}

	.ctaContainer {
		padding-top: 10px;
	}
}
