.wrapper {
	box-shadow: -1px 80px 100px 20px rgba(0, 0, 0, 0.1) inset;
}

.container {
	padding: 100px 20px;
}

.headerContainer {
	display: flex;
	align-items: flex-start;
	gap: 40px;
	width: 80%;
	margin: auto;
	margin-bottom: 80px;
}

.heading {
	flex-shrink: 0;
	border-left: 8px solid var(--red);
	padding: 20px 0 20px 20px;
}

.cardContainer {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: clamp(40px, 5%, 100px);
}

.card {
	text-decoration: none;
	color: var(--red);
	display: flex;
	flex-direction: column;
	gap: 40px;
	justify-content: center;
	text-align: center;
}

.imageContainer {
	background-color: var(--white);
	box-shadow: var(--shadow);
	border-radius: 50%;
	aspect-ratio: 1/1;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: var(--short-transition);
	outline: 6px solid var(--white);
	outline-offset: -6px;
}

.imageContainer img {
	scale: 1.02;
	width: 100%;
	height: auto;
	aspect-ratio: 1/1;
	object-fit: contain;
}

.card:hover .imageContainer {
	outline: 6px solid var(--red);
	outline-offset: 8px;
}

.ctaContainer {
	margin-top: 80px;
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 1024px) {
	.container {
		padding: 80px 5%;
	}

	.headerContainer {
		flex-direction: column-reverse;
	}

	.cardContainer {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 768px) {
	.headerContainer {
		width: 100%;
	}

	.cardContainer {
		grid-template-columns: 1fr;
	}

	.ctaContainer {
		padding-top: 60px;
	}
}
